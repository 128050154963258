import React from "react";
import Election from "../views/ElectionPage/Election";
import Meta from "../views/ElectionPage/Meta";
import Card from "../components/Card/Card";
import NationalCard from "../components/Card/NationalCard";
import SpreadGraph from "../components/Graph/SpreadGraph";
import Table from '../components/Table/Table';
import GraphView from "../views/GraphView/GraphView";
import CardMetadata from '../components/Card/CardMetadata';
import CountStory from "../components/CountStory/CountStory";
import NationalPicture from "../components/Graph/NationalPicture";

const Router = () => {
  let url = new URL(window.location.href);

  const electionProps = {
    constituency: url.searchParams.get("constituency"),
    datatype: url.searchParams.get("datatype"),
    election: url.searchParams.get("election"),
    mode: url.searchParams.get("mode"),
    region: url.searchParams.get("region"),
  };

  const nationalCardProps = {
    ...electionProps,
    source: url.searchParams.get("source"),
  };

  switch (url.searchParams.get("component")) {
    case "election":
      return <Election {...electionProps} />;
    case "table":
      return <Table {...electionProps} />;
    case "card":
      return <Card {...electionProps} />;
    case "cardmetadata":
      return <CardMetadata {...electionProps} />;
    case "graph":
      return <GraphView {...electionProps} />;
    case "nationalcard":
      return <NationalCard {...nationalCardProps} />;
    case "spreadgraph":
      return <SpreadGraph {...electionProps} />;
    case "meta":
      return <Meta {...electionProps} />;
    case "nationalpicture":
      return <NationalPicture {...electionProps} />
    case "countstory":
        return <CountStory  {...electionProps} />;
    default:
      return;
  }
};

export default Router;
