import React from 'react';
import Graph from '../../components/Graph/Graph.jsx';
import DoughnutGraph from '../../components/Graph/DoughnutGraph.jsx';

const GraphView = (props) => {
  if (props.constituency) {
    return (
      <div className='chart-container'>
        <Graph {...props} />
      </div>
    );
  }

  return (
    <div className='chart-container'>
      <Graph {...props} />
      <DoughnutGraph {...props} />
    </div>
  );
}

export default GraphView;
