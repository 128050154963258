import React, { useState, useEffect } from "react";
import "./CardMetadata.css";
import constituenciesJson from "../Data/constituencies.json";
import electionAPIData from "../../utils/election-api";
import colorFeeds from "../Data/coloursData.json";

const CardMetadata = ({ election, constituency }) => {
  const [partyColors, setPartyColors] = useState([]);
  const [seats, setSeats] = useState([]);
  const [metadata, setMetadata] = useState([]);
  const [ceanncomhairle, setCeannComhairle] = useState([]);
  const [currentConstituency, setCurrentConstituency] = useState(constituency);

  useEffect(() => {
    // Declare the party colours
    setPartyColors(colorFeeds);

    // Get number of seats filled in specified constituency
    const endpointParamsSeats = election + '/nationalpage';
    electionAPIData(endpointParamsSeats)
      .then((data) => {

        // Set the CC object
        if (data.ceanncomhairle) {
          setCeannComhairle(data.ceanncomhairle);
        }

        // Update the array - 'code' (constituenciesJson) and 'name.toLowetCase()'
        const updatedConstituencies = constituenciesJson[election].map(item => {
          const match = data.constituencies.find(constituency => constituency.name.replace(/\s+/g, '-').toLowerCase() === item.name.replace(/\s+/g, '-').toLowerCase());
          if (match && data.ceanncomhairle && data.ceanncomhairle.constituencyName === match.name) {
            match.elected.push({ name: data.ceanncomhairle.name, party: data.ceanncomhairle.party, firstpreference: 0, count: 'NA' })
          }
          if (match) {
            return { ...item, ...match };
          }
          return item;
        })
        const foundConstituency = updatedConstituencies.find(item => item.code === constituency);
        setSeats(foundConstituency.elected ? foundConstituency.elected : []);
      })
      .catch((err) => {
        console.log(err.message);
      });

    // Get brief set of data for specified constituency
    const endpointParams = election + "/constituencies/" + constituency + "/data";
    electionAPIData(endpointParams)
      .then((data) => {
        // If the array is empty (nullState), use "constituenciesJson" to get the "Seats" value
        if (data.length === 0) {
          const nullState = constituenciesJson[election].find(
            (item) => item.code === constituency
          );
          setMetadata(nullState);
        } else {
          setMetadata(data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  // Seats filled
  function Seats(props) {
    let seatsParty = [];
    let partyName = "";
    let seatsNumber = 0;

    // If this is the CC's constituency and people have been elected then use the seats object above
    // Note: The CC does not come through NERD as he's not up for re-election.....technically
    if (ceanncomhairle.constituencyCode === currentConstituency && props.seatsfilled.length > 0) {
      seatsNumber = props.ccConstituencySeats;
    } else {
      seatsNumber = props.totalseats;
    }

    for (let i = 0; i < seatsNumber; i++) {

      if (props.seatsfilled[i]) {
        // If the seat is filled, use the party name for color
        partyName = props.seatsfilled[i].party.toLowerCase();
      } else if (i === 0 && ceanncomhairle && props.seatsfilled.length === 0 && ceanncomhairle.constituencyCode === currentConstituency && ceanncomhairle.party) {
        //Set the name for the first rectangle to that of the CC's party
        partyName = [ceanncomhairle.party.toLowerCase()];
      } else {
        // If the seat is unfilled, set a default value for unfilled seats
        partyName = "unfilled";
      }

      // Set the party colour
      let electedBg = {
        backgroundColor: partyColors[0][partyName],
      };
      seatsParty.push(<div key={i} className="seats" style={electedBg}></div>);
    }
    return seatsParty;
  }

  // Metadata
  function Table(props) {
    return (
      <>
        <tr>
          <td>Electorate</td>
          <td className="metadata-td">
            {props.meta?.electorate ? props.meta.electorate.toLocaleString('en-US') : "..."}
          </td>
        </tr>
        <tr>
          <td>Quota</td>
          <td className="metadata-td">
            {props.meta?.quota ? props.meta.quota.toLocaleString('en-US') : "..."}
          </td>
        </tr>
        <tr>
          <td>Total Poll</td>
          <td className="metadata-td">
            {props.meta?.totalpoll ? props.meta.totalpoll.toLocaleString('en-US') : "..."}
          </td>
        </tr>
        <tr>
          <td>Spoiled</td>
          <td className="metadata-td">
            {props.meta?.spoiledvotes ? props.meta.spoiledvotes.toLocaleString('en-US') : "..."}
          </td>
        </tr>
        <tr>
          <td>Valid Poll</td>
          <td className="metadata-td">
            {props.meta?.totalvalidpoll ? props.meta.totalvalidpoll.toLocaleString('en-US') : "..."}
          </td>
        </tr>
        <tr>
          <td>Turnout</td>
          <td className="metadata-td">
            {props.meta?.turnout ? `${props.meta.turnout.toFixed(2)}%` : "..."}
          </td>
        </tr>
      </>
    );
  }

  return (
    <div className="CardMetadata">
      <div className="metadata">
        <table className="metadata-table">
          <tbody>
            <tr>
              <td>Seats</td>
              <td className="metadata-td">
                <div className="seats-td">
                  <Seats totalseats={metadata?.seats} seatsfilled={seats} ccConstituencySeats={seats.length} />
                </div>
              </td>
            </tr>
            <Table meta={metadata} />
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CardMetadata;
