import React, { useState, useEffect } from "react";
import "./NationalCard.css";
import electionAPIData from "../../utils/election-api";
import colorFeeds from "../Data/coloursData.json";
import regionsConfigData from "../Data/regionData.json";

const NationalCard = (props) => {
  const electionCode = props.election;
  const sourceDomain = props.source;

  const [constituencies, setConstituencies] = useState([]);
  const [partyColors, setPartyColors] = useState([]);
  const [regionsList, setRegionsList] = useState(["all"]);
  const [region, setRegion] = useState(props?.region || "all");
  const [startedCounts, setStartedCounts] = useState([]);

  const filteredConstituencies =
    region !== "all"
      ? constituencies?.filter((item) => {
          return (
            (item?.regionName || item.region)?.toLowerCase() ===
            region.toLowerCase()
          );
        })
      : constituencies;

  const [contentVisibility, setContentVisibility] = useState({});

  const toggleContent = (constCode) => {
    // Toggle visibility state for the clicked item
    setContentVisibility((prevState) => ({
      ...prevState,
      [constCode]: !prevState[constCode],
    }));
  };

   // Checks if all cards are currently expanded or not
   const allCurrentlyExpanded = filteredConstituencies.every(
    item => contentVisibility[item.code]
  );

  // Expands all if at least one is collapsed; collapses all if they’re already expanded.
  const toggleAllContent = () => {
    setContentVisibility(prevState => {
      const newVisibility = {};
      filteredConstituencies.forEach(item => {
        // Expand all if any card is collapsed, otherwise collapse all
        newVisibility[item.code] = !allCurrentlyExpanded; 
      });
      return { ...prevState, ...newVisibility };
    });
  };

  const fetchConstituenciesData = async () => {
    try {
      const nationalPageData = await electionAPIData(
        `${electionCode}/nationalpage`
      );
      const constituenciesData = await electionAPIData(
        `${electionCode}/constituencies`
      );

      return Promise.resolve({ nationalPageData, constituenciesData });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const matchConstsAndCeanComhairle = (
    nationalPageData,
    constituenciesData
  ) => {
    // Match national card and constituencies data by constituency code
    let updatedConstituencies = constituenciesData.map((item) => {
      const match = nationalPageData.constituencies.find(
        (constituency) => constituency.code === item.code
      );
      if (match) {
        return { ...item, ...match };
      }
      return item;
    });

    // Update Cean Comhairle if available

    if (nationalPageData.ceanncomhairle?.party) {
      updatedConstituencies = updatedConstituencies.map((constData) => {
        if (
          constData.code === nationalPageData.ceanncomhairle.constituencyCode
        ) {
          const ceanncomhairleData = {
            name: nationalPageData.ceanncomhairle.name,
            party: nationalPageData.ceanncomhairle.party,
            firstpreference: 0,
            count: "",
            isCeanncomhairle: true,
            title: "Ceann Comhairle",
          };
          if (constData.elected) {
            constData.elected.unshift(ceanncomhairleData);
          } else {
            constData.elected = [ceanncomhairleData];
          }
          constData.isCeanncomhairleConst = true;
        }

        return constData;
      });
    }

    return updatedConstituencies;
  };

  const matchAndSortRegions = (
    constituenciesData,
    electionType,
    regionsConfig
  ) => {
    const regionsArr = [
      ...new Set(
        constituenciesData.map((constituency) => constituency.regionName)
      ),
    ];

    const orderedRegionsList = [];

    // Match and sort regions by election Type from static config (regions ordered 1 - n)

    if (regionsConfig?.[electionType]) {
      regionsArr.forEach((region) => {
        const regionCfg = regionsConfig[electionType]?.[region.toLowerCase()];

        if (regionCfg) {
          orderedRegionsList[regionCfg.order - 1] = {
            region: region,
            displayName: regionCfg.displayName,
          };
        }
      });
    }

    return orderedRegionsList;
  };

  useEffect(() => {
    // Declare the party colours
    setPartyColors(colorFeeds);

    fetchConstituenciesData()
      .then(({ nationalPageData, constituenciesData }) => {
        const matchedConstituencies = matchConstsAndCeanComhairle(
          nationalPageData,
          constituenciesData
        );
        setConstituencies(matchedConstituencies);

        setStartedCounts(nationalPageData.started_counts);

        // Set the initial state for visibility as false
        setContentVisibility(
          Object.fromEntries(
            matchedConstituencies.map((constituency) => [
              constituency.code,
              false,
            ])
          )
        );

        const orderedRegions = matchAndSortRegions(
          constituenciesData,
          nationalPageData.type,
          regionsConfigData
        );
        setRegionsList(orderedRegions);
      })
      .catch((err) => console.log(err.message));
  }, []);

  function Election(props) {
    let election = Array.from({ length: props.totalseats }, (_, i) => {
      let electedCandidate = props.elected ? props.elected[i] : false;
      let partyName = electedCandidate ? electedCandidate.party : "";
      // Set the party colour
      let electedBg = {
        backgroundColor: partyColors[0][partyName.toLowerCase()],
      };
      if (props.type === "seats") {
        // Set the box with the party name and background colour / above the table
        return (
          <div key={i} className={"seats"} style={electedBg}>
            {partyName}
          </div>
        );
      } else if (props.type === "candidate") {
        // Set the party box (name and background color) / Candidate / Count elected / 1st Pref
        return (
          <tr key={i}>
            <td className="party-box">
              <div className={"seats-small"} style={electedBg}>
                {partyName}
              </div>
            </td>
            <td>{electedCandidate ? electedCandidate.name : "..."}</td>
            <td className="count-e">
              {electedCandidate ? electedCandidate.count : "..."}
            </td>
            <td className="first-pref">
              {electedCandidate
                ? electedCandidate.isCeanncomhairle
                  ? electedCandidate.title // Show title if isCeanncomhairle is true
                  : `${electedCandidate.firstpreference.toFixed(2)}%` // Otherwise, show first preference
                : "0%"}
            </td>
          </tr>
        );
      }
    });

    return <>{election}</>;
  }
  function SeatsFilled(props) {
    // Seats filled - check if it exists, if not set to "0"
    let seatsfilled = props.seatsfilled;
    let seats = props.totalseats;
    return (
      <>
        <strong>
          {seatsfilled}/{seats}
        </strong>{" "}
        seats filled
      </>
    );
  }

  function ElectionStatus(props) {
    // Strip all numbers from the constituencyCode (IS2 -> IS)
    let constCode = props.constituencyCode.replace(/[0-9]/g, "");
    // Election Status - complete / counting / pending
    if (props.countStatus == "c") {
      return (
        <>
          <div className="icon complete">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
            </svg>
          </div>
          <div className="text complete">COMPLETE</div>
        </>
      );
    } else if (startedCounts?.includes(constCode)) {
      return (
        <>
          <div className="icon counting">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 14"
              fill="none"
            >
              <path
                d="M7.592 10.128C7.848 10.512 8 10.968 8 11.464V12.4C8 12.952 7.552 13.4 7 13.4H1C0.448 13.4 0 12.952 0 12.4V11.464C0 10.968 0.152 10.504 0.408 10.12C0.84 9.48001 1.576 9.14401 2.328 9.14401C2.576 9.14401 2.824 9.18401 3.072 9.25601C3.368 9.34401 3.68 9.39201 4 9.39201C4.32 9.39201 4.632 9.34401 4.928 9.25601C5.168 9.18401 5.424 9.14401 5.672 9.14401C6.424 9.14401 7.16 9.48001 7.592 10.128ZM1.6 6.20001C1.6 4.87201 2.672 3.80001 4 3.80001C5.328 3.80001 6.4 4.87201 6.4 6.20001C6.4 7.52801 5.328 8.60001 4 8.60001C2.672 8.60001 1.6 7.52801 1.6 6.20001ZM16 1.84001V9.76001C16 10.448 15.464 11 14.8 11H8.8V9.80001H14.8V1.80001H5.2V3.24001C5.2 3.24001 5.128 3.22401 5.096 3.20801C5 3.16801 4.896 3.13601 4.792 3.11201C4.664 3.08001 4.528 3.05601 4.392 3.04001C4.28 3.01601 4.16 3.00001 4 3.00001V1.84001C4 1.15201 4.536 0.600006 5.2 0.600006H14.8C15.464 0.600006 16 1.15201 16 1.84001ZM9.576 7.02401L8.2 5.64801L7.152 6.69601C7.176 6.52801 7.2 6.36801 7.2 6.20001C7.2 5.82401 7.128 5.47201 7.008 5.14401L7.776 4.37601C8.008 4.14401 8.392 4.14401 8.624 4.37601L10 5.75201L11.376 4.37601L10.768 3.76801C10.48 3.48801 10.688 3.00001 11.088 3.00001H13.304C13.472 3.00001 13.608 3.13601 13.608 3.30401V5.52001C13.608 5.92001 13.12 6.12001 12.84 5.84001L12.232 5.23201L10.432 7.03201C10.2 7.26401 9.816 7.26401 9.584 7.03201L9.576 7.02401Z"
                fill="#E07000"
              />
            </svg>
          </div>
          <div className="text counting">UNDER WAY</div>
        </>
      );
    } else {
      return (
        <>
          <div className="icon pending">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H464c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0s-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3s163.8-62.5 226.3 0L386.3 160z" />
            </svg>
          </div>
          <div className="text pending">PENDING</div>
        </>
      );
    }
  }

  return (
    constituencies?.[0] && (
      <div className="NationalCard-container">
        <div
          aria-label="Filter constituency results by region"
          className="region-filter-group"
          role="group"
        >
          {regionsList.map((regData) => {
            return (
              <button
                onClick={() => {
                  setRegion((prevRegion) =>
                    prevRegion === regData.region ? "all" : regData.region
                  );
                }}
                className="region-filter-btn"
                aria-pressed={regData.region === region}
                key={regData.region}
              >
                {regData.displayName}
              </button>            
            );
          })}
          
          <button className="collapse" onClick={() => toggleAllContent()} aria-label={allCurrentlyExpanded ? "Collapse All Cards" : "Expand All Cards"} >
            {allCurrentlyExpanded ? 'Collapse All' : 'Expand All'}
              <svg className={`arrow ${!allCurrentlyExpanded ? "rotated" : ""}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" >
                <path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" />
              </svg>
          </button>

        </div>
        <div className="NationalCard NationalCard-list">
          {filteredConstituencies?.map((constituency, index) => {
            const fullPageLink =
              sourceDomain?.toUpperCase() === "IT"
                ? constituency?.fullPageLinks.it_url
                : constituency?.fullPageLinks.ie_url;

            return (
              <div key={index}>
                <div>
                  <div className="header-card">
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: constituency.displayName
                          ? constituency.displayName
                          : constituency.name,
                      }}
                    />
                    <button
                      className={`arrow ${
                        !contentVisibility[constituency.code] ? "rotated" : ""
                      }`}
                      onClick={() => toggleContent(constituency.code)}
                      aria-label="Collapse Card"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" />
                      </svg>
                    </button>
                  </div>
                  <div className="container-card">
                    <Election
                      elected={constituency.elected}
                      totalseats={Number(constituency.setseats || 0)}
                      type={"seats"}
                    />
                  </div>
                  <div className="status">
                    <div>
                      <SeatsFilled
                        seatsfilled={
                          constituency.isCeanncomhairleConst
                            ? (constituency.seatsfilled || 0) + 1
                            : constituency.seatsfilled || 0
                        }
                        totalseats={Number(constituency.setseats || 0)}
                      />
                    </div>
                    <div>
                      <ElectionStatus
                        countStatus={constituency.countStatus}
                        constituencyCode={constituency.code}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`content-card ${
                    !contentVisibility[constituency.code] ? "hide" : ""
                  }`}
                >
                  <div className="count-elected">
                    <div>COUNT ELECTED</div>
                    <div>1ST PREF</div>
                  </div>
                  <table className="full-data">
                    <tbody>
                      <Election
                        totalseats={Number(constituency.setseats || 0)}
                        elected={constituency.elected}
                        type={"candidate"}
                      />
                    </tbody>
                  </table>
                  {sourceDomain && (
                    <div>
                      {fullPageLink ? (
                        <a className="view-full-btn" href={fullPageLink}>
                          View Full Results
                        </a>
                      ) : (
                        <span className="view-full-btn no-link">
                          View Full Results
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    )
  );
  
};

export default NationalCard;

