import React, { useState, useEffect, useId } from 'react';
import { ReactTabulator } from 'react-tabulator';
import 'react-tabulator/css/bootstrap/tabulator_bootstrap.min.css';
import 'react-tabulator/lib/styles.css';
import "./table.css";
import electionAPIData from "../../utils/election-api";
import partyColours from "../Data/coloursData.json";

const Table = ({ constituency, election }) => {
  const [tableData, setData] = useState([]);
  const [tableColumns, setColumns] = useState([]);
  const [tableOptions, setOptions] = useState([]);
  const [sortOption, setSort] = useState('firstpref_desc');
  const [hasScrollbar, setScrollbar] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const id = useId();
  let ref = React.useRef();

  useEffect(() => {
    // fetch data for the constituency
    electionAPIData(`${election}/constituencies/${constituency}/fulldata`).then(electionData => {
      setOptions({
        initialSort: [{ column: "firstpref", dir: "desc" }],
        layout: "fitDataStretch"
      });

      let columns = [
        { title: "CANDIDATE", field: "candidate", width: 240, vertAlign: "middle", hozAlign: "left", headerHozAlign: "right", formatter: "html", frozen: "true", cssClass: "candidates" },
        { title: "1ST PREF", field: "firstpref", width: 90, vertAlign: "middle", hozAlign: "right", headerHozAlign: "right", sorter: "number" }
      ];
      let data = [];
      let candidates = [];
      let totalFirstPref = 0;

      // if election is in null state then return candidates with default values
      if (!electionData.meta.turnout > 0) {
        columns.push({ title: "COUNT 1", field: "count1", width: 90, vertAlign: "middle", hozAlign: "right", headerHozAlign: "right", formatter: "html" });

        electionData.countresults.forEach((candidate, index) => {
          candidates[index] = {
            "name": candidate[index]["name"],
            "party": candidate[index]["code"],
            "status": "",
            "firstpref": "-",
            "count1": "-"
          };
        });
      } else {

        // once election has begun, parse each count with some additional processing of data
        electionData.countresults.forEach((count, index) => {
          columns.push({ title: `COUNT ${index + 1}`, field: `count${index + 1}`, width: 90, vertAlign: "middle", hozAlign: "right", headerHozAlign: "right", formatter: "html" });
          delete count.title;
          delete count.nontransferablevotes;

          for (const candidate in count) {
            if (index === 0) {
              totalFirstPref += count[candidate]["votes"];

              candidates[count[candidate]["hash"]] = {
                "name": count[candidate]["name"],
                "party": count[candidate]["code"],
                "status": getCandidateStatus(count[candidate]["elected"], count[candidate]["eliminated"]),
                "firstpref": count[candidate]["votes"],
                "count1": formatCount(count[candidate]["votes"], count[candidate]["elected"])
              };
            } else {
              candidates[count[candidate]["hash"]][`count${index + 1}`] = formatCount(count[candidate]["votes"], count[candidate]["elected"]);
              candidates[count[candidate]["hash"]]["status"] = getCandidateStatus(count[candidate]["elected"], count[candidate]["eliminated"])
            }
          }
        });
      }

      // Dummy column to ensure table occupies full width
      columns.push({ title: "", field: "", headerSort: false });

      // additional formatting once data has been processed
      for (const [key, value] of Object.entries(candidates)) {
        value.candidate = formatCandidate(value.name, value.party, value.status);
        value.firstpref = formatFirstPref(value.firstpref, totalFirstPref);

        data.push(value);
      }

      setData(data);
      setColumns(columns);
    })
  }, []);

  // returns formatted candidate cell using name, party and election status
  const formatCandidate = (name, party, status) => {
    return `
      <span class="candidate">
        <span class="name">${name}</span>
        <span class='status'>${getStatusIcon(status)}</span>
      </span>
      <span class="party" style="background-color: ${getPartyColour(party)};">${party.toUpperCase()}</span>`;
  }

  // returns candidate election status
  const getCandidateStatus = (elected, eliminated) => {
    if (elected) {
      return "elected";
    } else if (eliminated) {
      return "eliminated";
    } else {
      return "notElected";
    }
  }

  // returns icon indicating the candidate's election status
  const getStatusIcon = (status) => {
    switch (status) {
      case "elected":
        return `
          <svg xmlns="http://www.w3.org/2000/svg" class="bi bi-check2" stroke="#46B33C" stroke-width="1" viewBox="0 0 16 16">
            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0"/>
          </svg>`;
      case "eliminated":
        return `
          <svg xmlns="http://www.w3.org/2000/svg" class="bi bi-x" stroke="#DB0A07" stroke-width="1" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
          </svg>`;
      default:
        return `
          <svg xmlns="http://www.w3.org/2000/svg" class="bi bi-dash" stroke="#515151" stroke-width="1.5" viewBox="0 0 16 16">
            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8"/>
          </svg>`;
    }
  }

  // returns hex value for the party colour
  const getPartyColour = (party) => {
    return partyColours[0][party.toLowerCase()] ?? "#000";
  }

  // returns formatted firstpref cell as a % of total first count votes
  const formatFirstPref = (firstpref, totalFirstPref) => {
    return firstpref === "-" ? firstpref : `${(firstpref / totalFirstPref * 100).toFixed(2)}%`;
  }

  // returns formatted cell with additional styling if candidate elected on that count
  const formatCount = (votes, elected) => {
    return `<span ${elected ? 'class="count-elected"' : ''}>${votes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>`;
  }

  // Returns the table
  const Table = () => {
    return (
      <ReactTabulator
        id={`table-${id}`}
        onRef={(r) => (ref = r)}
        data={tableData}
        columns={tableColumns}
        options={tableOptions}
        events={{
          renderComplete: tableRendered
        }} />
    );
  }

  // additional work once the table has completed rendering
  const tableRendered = () => {
    setMobile(ref.current.browserMobile);

    let table = document.getElementById(`table-${id}`);
    if (table.scrollWidth > table.clientWidth) {
      setScrollbar(true);
    }
  }

  // Returns a label prompting the user to scroll across
  const ScrollPrompt = (props) => {
    if (props.hasScrollbar) {
      return (<div className="scroll-prompt">{props.isMobile ? "Swipe across to view all counts" : "Use bar at bottom to move across to later counts"}</div>);
    } else {
      return null;
    }
  }

  // Sorts the table & updates the selected option in the dropdown menu
  const sortTable = (option) => {
    setOptions({
      initialSort: [
        { column: option.split('_')[0], dir: option.split('_')[1] }
      ],
      layout: "fitDataStretch"
    });

    setSort(option);
  }

  return (
    <div className="table">
      <h2 className="title">Results by count</h2>
      <div className="controls">
        <div className="dropdown-menu">
          <label htmlFor={`sort-${id}`}>SORT</label>
          <select id={`sort-${id}`} value={sortOption} onChange={e => sortTable(e.target.value)}>
            <option value="candidate_asc">Candidate (A-Z)</option>
            <option value="candidate_desc">Candidate (Z-A)</option>
            <option value="firstpref_asc">%1st PREF &#9650;</option>
            <option value="firstpref_desc">%1st PREF &#9660;</option>
          </select>
        </div>
        <ScrollPrompt isMobile={isMobile} hasScrollbar={hasScrollbar} />
      </div>
      <Table />
      <ScrollPrompt isMobile={isMobile} hasScrollbar={hasScrollbar} />
    </div>
  );
};

export default Table;
