import axios from "axios";
import { SAVED_ELECTIONS } from "../config/saved-elections";

const getStaticPathFromQuery = (queryPath) => {
  const queryArr = queryPath.split("/");
  const electionCode = queryArr[0];
  let electionCodeFilePath = "";

  if (queryArr.includes("constituencies")) {
    const constituency = queryArr[queryArr.indexOf("constituencies") + 1];
    const dataFile = queryArr[queryArr.indexOf("constituencies") + 2];

    if (dataFile) {
      electionCodeFilePath = `/static/election-data/${electionCode}/constituencies/${constituency}/${dataFile}.json`;
    } else {
      electionCodeFilePath = `/static/election-data/${electionCode}/constituencies.json`;
    }

  } else {
    electionCodeFilePath = `/static/election-data/${electionCode}/nationalpage.json`;
  }

  const electionCodeObj = {
    electionCode,
    electionCodeFilePath: process.env.REACT_APP_HOST_URL + electionCodeFilePath,
  };

  return electionCodeObj;
};

async function electionAPIData(queryParams) {
  const { electionCode, electionCodeFilePath } =
    getStaticPathFromQuery(queryParams);

  if (SAVED_ELECTIONS.includes(electionCode)) {
    // Fetch election data from /public/static/election-data

    try {
      const staticData = await axios.get(electionCodeFilePath, {
        responseType: "json",
        transitional: {
          silentJSONParsing: false,
          forcedJSONParsing: false,
        },
      });

      return Promise.resolve(staticData.data);
    } catch (staticDataError) {
      console.log("No Static Data Found: " + staticDataError);
      return Promise.reject(staticDataError);
    }
  } else {
    try {
      // Fetch election data from NERD api
      const liveData = await axios.get(
        process.env.REACT_APP_ELECTION_API_URL + queryParams
      );
      return Promise.resolve(liveData.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default electionAPIData;
