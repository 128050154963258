import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export const options = {
  plugins: {
    title: {
      display: true,
      text: 'Chart.js Bar Chart - Stacked',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  indexAxis: 'y',
  type: 'bar'
};

var backgroundColors;

const Election = () => {
  const [votes, setVotes] = useState([]);

  useEffect(() => {
    const blackColor = 'rgb(51, 51, 51)';
    const colorFeeds = {
      sf: 'rgb(39, 112, 70)',
      ff: 'rgb(64, 179, 79)',
      fg: 'rgb(47, 53, 144)',
      io: blackColor,
      wua: blackColor,
      ind: blackColor,
      oth: blackColor,
      gp: 'rgb(180, 209, 68)',
      lab: 'rgb(199, 40, 49)',
      sd: 'rgb(102, 0, 230)',
      spbp: 'rgb(196, 86, 139)',
    }
    fetch('https://www.irishtimes.com/election-api/api/public/v1/elections/gen2016/constituencies/TIP/fulldata?ts=1713179655856')
      .then((response) => response.json())
      .then((data) => {
        let dataSet = [];
        data.voteShare.sort(function (a, b) {
          return b.votes - a.votes;
        });
        data.voteShare.forEach((value, index) => {

          let percentage = (value.votes / data.meta.totalvalidpoll) * 100;
          percentage = percentage.toFixed(2);
          if (value.party !== 'IND' && value.party != 'OTH') {

            dataSet.push({
              backgroundColor: colorFeeds[value.party.toLowerCase()],
              data: [percentage],
              label: `${value.party} - ${percentage}%`,
            })
          }

        });
        setVotes(dataSet);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const labels = ['Spread'];
  const chartData = {
    labels,
    datasets: votes
  };

  return <Bar options={options} data={chartData} />;
};


export default Election;
