import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

if (document.getElementById("root")) {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(<App />);
}

// Web Components
// Expose React components as web components, and define their attributes via props
import r2wc from "@r2wc/react-to-web-component";
import GraphView from "../src/views/GraphView/GraphView";
import Card from "../src/components/Card/Card";
import CardMetadata from "../src/components/Card/CardMetadata";
import Table from "../src/components/Table/Table";
import NationalCard from "./components/Card/NationalCard";
import NationalPicture from "./components/Graph/NationalPicture";
import CountStory from "./components/CountStory/CountStory";

const wcGraphView = r2wc(GraphView, {
  props: { constituency: "string", election: "string", mode: "string" },
});

const wcCard = r2wc(Card, {
  props: { constituency: "string", election: "string", region: "string" },
});

const wcCardMetaData = r2wc(CardMetadata, {
  props: { constituency: "string", election: "string" },
});

const wcTable = r2wc(Table, {
  props: { constituency: "string", election: "string" },
});

const wcNationalCard = r2wc(NationalCard, {
  props: { election: "string", region: "string", source: "string" },
});

const wcNationalPicture = r2wc(NationalPicture, {
  props: { election: "string" },
});

const wcCountStory = r2wc(CountStory, {
  props: { election: "string", constituency: "string" },
});

// <it-election-graphview>
customElements.define("it-election-graphview", wcGraphView);

// <it-election-card>
customElements.define("it-election-card", wcCard);

// <it-election-cardmetadata>
customElements.define("it-election-cardmetadata", wcCardMetaData);

// <it-election-table>
customElements.define("it-election-table", wcTable);

// <it-election-nationalcard>
customElements.define("it-election-nationalcard", wcNationalCard);

// <it-election-nationalpicture>
customElements.define("it-election-nationalpicture", wcNationalPicture);

// <it-election-countstory>
customElements.define("it-election-countstory", wcCountStory);