import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import electionAPIData from "../../utils/election-api";
import spreadsheetAPIData from "../../utils/spreadsheet-api";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: 'Chart.js Bar Chart - Stacked',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  indexAxis: 'y',
};

var backgroundColors;

const Election = ({ election, constituency, mode, datatype }) => {
  const [votes, setVotes] = useState([]);

  useEffect(() => {
    const blackColor = 'rgb(51, 51, 51)';
    const colorFeeds = {
      sf: 'rgb(39, 112, 70)',
      ff: 'rgb(64, 179, 79)',
      fg: 'rgb(47, 53, 144)',
      io: blackColor,
      wua: blackColor,
      ind: blackColor,
      gp: 'rgb(180, 209, 68)',
      lab: 'rgb(199, 40, 49)',
      sd: 'rgb(102, 0, 230)',
      spbp: 'rgb(196, 86, 139)',
    }

    //Form query parameters
    const endpointParams = election + '/' + datatype + '/' + constituency + '/seats/filled';
    //Election function to fetch data
    electionAPIData(endpointParams)
      .then((data) => {
        const setColors = (data) => {
          let colorArray = [];
          data.forEach((value, index) => {
            colorArray.push(colorFeeds[value.party.toLowerCase()]);
          });
          return colorArray;
        };

        backgroundColors = setColors(data);
        setVotes(data);
      })
      .catch((err) => {
        console.log(err.message);
      });

    //Spreadsheet function to fetch data for local
    spreadsheetAPIData()
      .then(userData => {
        // Add code here
        console.log(userData);
      })
      .catch(error => {
        console.error('Error:', error);
      });

  }, []);


  const labels = votes.map(row => row.name);
  const chartData = {
    labels,
    datasets: [
      {
        label: '# of Votes',
        data: votes.map(row => row.votes),
        backgroundColor: backgroundColors,
        borderColor: 'blue',
        borderWidth: 1
      },
    ],
  };

  return <Bar options={options} data={chartData} />;
};


export default Election;
